import { TableRow, TableCell, ListItemText, Typography, IconButton, Grid, TableBody, TableHead, MenuItem, Menu, TableContainer, Link, ListItem, Icon, Button, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from "@material-ui/core";
import { Cancel, CancelOutlined, Edit, GetApp, MoreHoriz, RemoveCircleOutline, Replay, Timer, CheckCircleOutline, ErrorOutline, Cached, People, Settings, PlayArrow, ToggleOn, ToggleOff } from "@material-ui/icons";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useEffect, useState } from "react";
import { Collapse, Table, Tooltip } from "react-bootstrap";
import clsx from 'clsx';
import { CancelBatch, CancelBatchConsole, getBatchOrders, DownloadAllResults} from "./RestService";
import { useMsal } from "@azure/msal-react";
import CustomStatusIcon from "./CustomStatusIcon";
import { GridWindow } from "@material-ui/data-grid";
import { useSnackbar } from "react-simple-snackbar";
import { successSnackbarOptions, errorSnackbarOptions, warningSnackbarOptions } from "./SnackbarOptions";
import { ConvertedTimeString, GetEmpty } from "./Common";
import config from "../config";


export default function BatchRow(props) {
    const [currentSelection, setCurrentSelection] = useState(props.currentSelection);
    const [orders, setOrders] = useState(null);
    const [orderElements, setCurrentOrderElements] = useState(null);
    const [selected, setSelected] = useState(false);
    const [lock, setLock] = useState(false);
    const [mainKey, setMainKey] = useState(1);
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const { instance, accounts } = useMsal();
    const classes = props.classes;
    const globalClasses = props.globalClasses;
    const index3 = props.index;
    const [b, setB] = useState(props.batch == null ? null : props.batch);
    const d = props.material;
    const shareObj = props.shareObj;
    const [errorPopupOpen, setErrorPopupOpen] = useState(false); 
    const [popupMessage, setPopupMessage] = useState('');
    const [circularProgressOpen, setCircularProgressOpen] = useState(false);

    const GetRunningNum = (orderName) => {
        let splitName = orderName.split("-");
        if (splitName.length >= 2) {   
            return splitName[splitName.length - 2].slice(-4);
        }
        else {
            return orderName.slice(-4);
        }
    };

    const handleDownloadClick = (event, clickedOrder, clickedBatch) => {
        props.setAnchorElDownload(event.currentTarget);
        props.setClickedOrder(clickedOrder);
        props.setSelectedBatch(clickedBatch);
    };


    const getSuccessRate = (batch) => {
        //console.log("batch: ", batch);
        let successRate = "0.00%"
        let successOrders = 0;
        let failureOrders = 0;
        if (batch["orders"].length > 0) {
            batch["orders"].forEach(order => {
              if (order["status"] == "FAILED")
                failureOrders = failureOrders + 1
              else if (order["status"] == "JOB COMPLETED")
                successOrders = successOrders + 1                           
            });
            successRate = (successOrders/batch["executeOrders"].length*100.0).toFixed(2).toString() + "%"
        }
        return successRate;
    }
    const handleBatchClick = (index, clickedBatch) => {
        // console.log("B: ", b);
        // console.log("INDEX: " + index);
        console.log("clickedBatch: ", clickedBatch);
        setSelected(!selected);
        if (orderElements == null)
        {
            let tempOrders = [];
            let orderElements = [];
            if (clickedBatch["orders"].length > 0)
            {
                for (let oIndex = 0; oIndex < clickedBatch["orders"].length; oIndex++)
                {
                    tempOrders.push(clickedBatch["orders"][oIndex]);
                }
            }
            tempOrders.sort((a,b) => parseInt(GetRunningNum(a["name"]), 10) - parseInt(GetRunningNum(b["name"]), 10));
            setOrders(tempOrders);
            for (let index = 0; index < tempOrders.length; index++) {
                const element = tempOrders[index];
                //console.log("ELEMENT: ", element);
                let desc = "";
                let caD_duration = "";
                let caD_loops = "";
                
                if (element["description"])
                {
                    desc = element["description"];
                }
                if (element["caD_duration"] != "not available")
                {
                    caD_duration = element["caD_duration"];
                }
                if (element["caD_loops"] != "not available")
                {
                    caD_loops = element["caD_loops"];
                }
                
                let statusIcon = (<CustomStatusIcon status={"Not run"}></CustomStatusIcon>)
                if (element["status"])
                {
                  if (element["status"] == "Processing")
                    statusIcon = (<CustomStatusIcon status={"Processing"}></CustomStatusIcon>)
                  else if (element["status"] == "FAILED")
                    statusIcon = (<CustomStatusIcon status={"Failed"}></CustomStatusIcon>)
                  else if (element["status"] == "JOB COMPLETED")
                    statusIcon = (<CustomStatusIcon status={"Success"}></CustomStatusIcon>) 
                }
                let pdmUrl = "https://pdm-dq.konenet.com/kone_epdm_dq/#!object?obj_id=test_case:";
                if (config.environment == "prod")
                {
                    pdmUrl = "https://pdm.konenet.com/kone_production/#!object?obj_id=test_case:";
                }

                let pdmConfUrl = "https://pdm-dq.konenet.com/kone_epdm_dq/#!object?obj_id=item:";
                if (config.environment == "prod")
                {
                    pdmConfUrl = "https://pdm.konenet.com/kone_production/#!object?obj_id=item:";
                }
                
                orderElements.push(
                    <TableRow key={"orders_data_" + (index) + "_" + element["name"] + mainKey} className={classes.orderRow}>
                        
                        <TableCell className={classes.newbatchCell} align="center" colSpan={2}>
                            <TableRow style={{width:"100%", display:"flex"}}>
                                <TableCell className={classes.newbatchCell} align="left" style={{fontSize: "smaller", width: "75%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}}>
                                <ListItem style={{ paddingLeft: "0px"}}>
                                    <ListItemText
                                        style={{ overflow: "ellipsis"}}
                                        disableTypography
                                        primary={
                                        element["status"] != "Not run" && element["status"] != "Processing" ?
                                        <Typography className={globalClasses.smallFont}><Link target="_blank" href={pdmUrl + element["name"]}>{element["name"]}</Link></Typography>
                                        :
                                        <Typography className={globalClasses.smallFont}>{element["name"]}</Typography>
                                    }
                                        secondary={<Tooltip title={desc}><Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{desc.length > 46 ? desc.slice(0, 43) + "..." : desc}</Typography> </Tooltip>
                                        } 
                                    />
                                
                                </ListItem>
                                </TableCell>
                                <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", width:"25%", borderBottom: "0px solid", marginLeft: "13%", margin: "auto"}} align="right">
                                {
                                    element["configurationId"] != null 
                                    ?  
                                    element["configurationId"] != ""
                                    ?
                                    element["configurationId"] != "not available"
                                    ?
                                    <Tooltip title={"Link to configuration"}>
                                    <IconButton className={globalClasses.customButton} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => window.open(pdmConfUrl + element["configurationId"], '_blank')}>
                                        <Settings />
                                    </IconButton>
                                    </Tooltip>
                                    :
                                    <></>
                                    :
                                    <></>
                                    : 
                                    <></>
                                }
                                </TableCell>
                            </TableRow>
                        </TableCell>
                        <TableCell className={classes.newbatchCell} align="center">
                            <Tooltip title={element["status"]}>
                                {statusIcon}
                                {/* <RemoveCircleOutline className={classes.NotRunIcon} /> */}
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.newbatchCell} align="right" colSpan={3}>
                            <TableRow style={{width:"100%", display:"flex"}}>
                                <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"49%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="center">
                                  
                                </TableCell>
                                <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"51%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="right">
                                {
                                    GetEmpty(element["configurationId"]) == false && element["configurationId"] != "not available"
                                    ?
                                    <IconButton className={globalClasses.customButton} style={{float:"left"}} aria-controls="simple-menu" align="left" aria-haspopup="true" onClick={(e) => handleDownloadClick(e, element, clickedBatch)}>
                                        <GetApp />
                                    </IconButton>
                                    :
                                    <IconButton disabled={true} className={globalClasses.customButton} style={{float:"left"}} aria-controls="simple-menu" align="left" aria-haspopup="true" onClick={(e) => handleDownloadClick(e, element, clickedBatch)}>
                                        <GetApp />
                                    </IconButton>   
                                }
                                {statusIcon} 
                                </TableCell>
                            </TableRow>
                        </TableCell>
                        <TableCell className={classes.newbatchCell} align="center" colSpan={4}>
                            <TableRow  style={{width:"100%", display:"flex"}}>
                                <TableCell className={classes.orderCell} align="center" style={{fontSize: "smaller", width: "50%"}}>
                                    <Tooltip title={"Cad Duration"} placement="bottom-start">
                                    <Grid container direction="row" alignItems="center" spacing={1} justifyContent="center">
                                        <Timer fontSize='small' />
                                        <Typography style={{paddingLeft: "4px"}}> {caD_duration}</Typography>
                                    </Grid>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={classes.orderCell} style={{paddingRight: "8px", fontSize: "smaller", width:"50%", paddingTop: "2%"}} align="right">
                                    <Tooltip title={"Cad Loops"} placement="bottom-start">
                                    <Grid container direction="row" alignItems="center" spacing={1} justifyContent="center">
                                        <Replay fontSize='small' />
                                        <Typography style={{paddingLeft: "4px"}}> {caD_loops}</Typography>
                                    </Grid>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableCell>
                    </TableRow>
                    );    
            }
            setCurrentOrderElements(orderElements);
        }
        props.handleBatchClick(index, clickedBatch);
    }

    const handleEditClick = (e, clickedBatch, kmID, kmRev, clickedOrders) => {
        //console.log(clickedBatch);
        console.log("clickedBatch: ", clickedBatch);
        console.log("kmID: ", kmID);
        console.log("kmRev: ", kmRev);
        console.log("clickedOrders: ", clickedOrders);
        props.handleEditClick(e, clickedBatch, kmID, kmRev, clickedOrders)
    };

    const handleErrorClick = (b, element) => {
        const errors = GetOrderErrorModeErrors(b, element)[0];
        console.log("errorsStr: ", errors);
        props.handleSetErrorModeDialog(errors);
    }
    
    const handleOpenErrorPopup = (b, element) => {
        setPopupMessage(element["popupMsgStatus"]);
        setErrorPopupOpen(true);
    };

     const handleCloseErrorPopup = () => {
        setErrorPopupOpen(false);
    };

    const handleBatchDownloadClick = (b) => {
        let tempBatch = b;
        let tmpOrders = [];
        if (!GetEmpty(tempBatch["orders"]))
        {
            tempBatch["orders"].forEach(element => {
                if(element["status"] == "JOB COMPLETED") {
                    tmpOrders.push(element["configurationId"])
                }
            });
        }
        let batchName = tempBatch["name"];
        let batchMaterialName = tempBatch["material"] + "/" + tempBatch["materialRevision"];
        let batchOrderListsName = tmpOrders.map(String).join(',');
        if(tmpOrders.length > 0) {
            sessionStorage.setItem("circularProgress", "true");
            setCircularProgressOpen(true);
            DownloadAllResults(instance, batchName, batchOrderListsName, "DWN", accounts[0].username);
        } else {
            alert("There is no test orders results found for this batch");
            return;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            let circularProgress = sessionStorage.getItem("circularProgress");
			if(circularProgress=="false")
			{
				setCircularProgressOpen(false);
                if(document.getElementById("CirProg"))
                {
                    document.getElementById("CirProg").style.display="none";
                }
			}
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const CancelButton = async (clickedBatch) => {
        setLock(true);
        console.log("clickedBatch: ", clickedBatch);
        let message = "";
        let answer = window.confirm("Are you sure you want to cancel the run?");
        if (answer == true)
        {
            const cancelBatchResult = await CancelBatch(instance, clickedBatch["id"], clickedBatch["orders"]);
            if (cancelBatchResult["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{cancelBatchResult["exception"]}</p></div>,300000)
                setLock(false);
            }            
            else
            {
              openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch execute stopped."}</p></div>,300000);
              clickedBatch["status"] = "Cancelled";
              props.handleCancelClick(clickedBatch);
              props.setReRender(!props.reRender);
            }
            const cancelBatchConsoleResult = await CancelBatchConsole(instance, accounts[0].username, clickedBatch["name"],clickedBatch["lastRun"]);
            setLock(false);
            if (cancelBatchConsoleResult == "Failed") {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Console side failed to cancel batch."}</p></div>,300000)
            }  
        }
    };

    const RefreshOrders = () => {
        let tempOrders = [];
        let orderElements = [];
        if (b["orders"].length > 0)
        {
            for (let oIndex = 0; oIndex < b["orders"].length; oIndex++)
            {
                tempOrders.push(b["orders"][oIndex]);
            }
        }
        tempOrders.sort((a,b) => parseInt(GetRunningNum(a["name"]), 10) - parseInt(GetRunningNum(b["name"]), 10));
        setOrders(tempOrders);
        for (let index = 0; index < tempOrders.length; index++) {
            const element = tempOrders[index];
            //console.log("ELEMENT: ", element);
            let desc = "";
            let caD_duration = "";
            let caD_loops = "";
            
            if (element["description"])
            {
                desc = element["description"];
            }
            if (element["caD_duration"] != "not available")
            {
                caD_duration = element["caD_duration"];
            }
            if (element["caD_loops"] != "not available")
            {
                caD_loops = element["caD_loops"];
            }
            
            let statusIcon = (<CustomStatusIcon status={"Not run"}></CustomStatusIcon>)
            if (element["status"])
            {
                if (element["status"].toLowerCase() == "processing")
                statusIcon = (<CustomStatusIcon status={"Processing"}></CustomStatusIcon>)
                else if (element["status"].toLowerCase() == "failed")
                statusIcon = (<CustomStatusIcon status={"Failed"}></CustomStatusIcon>)
                else if (element["status"].toLowerCase() == "job completed")
                statusIcon = (<CustomStatusIcon status={"Success"}></CustomStatusIcon>) 
            }
            let pdmUrl = "https://pdm-dq.konenet.com/kone_epdm_dq/#!object?obj_id=test_case:";
            if (config.environment == "prod")
            {
                pdmUrl = "https://pdm.konenet.com/kone_production/#!object?obj_id=test_case:";
            }

            let pdmConfUrl = "https://pdm-dq.konenet.com/kone_epdm_dq/#!object?obj_id=item:";
            if (config.environment == "prod")
            {
                pdmConfUrl = "https://pdm.konenet.com/kone_production/#!object?obj_id=item:";
            }
            
            orderElements.push(
                <TableRow key={"orders_data_" + (index) + "_" + element["name"] + mainKey} className={classes.orderRow}>
                    
                    
                    <TableCell className={classes.newbatchCell} align="center" colSpan={2}>
                        <TableRow style={{width:"100%", display:"flex"}}>
                            <TableCell className={classes.newbatchCell} align="left" style={{fontSize: "smaller", width: "75%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%", marginLeft: "13%"}}>
                            <ListItem style={{ paddingLeft: "0px"}}>
                            <ListItemText
                                                            style={{ overflow: "ellipsis"}}
                                                            disableTypography
                                                            primary={
                                                            element["status"] != "Not run" && element["status"] != "Processing" ?
                                                            <Typography className={globalClasses.smallFont}><Link target="_blank" href={pdmUrl + element["name"]}>{element["name"]}</Link></Typography>
                                                            :
                                                            <Typography className={globalClasses.smallFont}>{element["name"]}</Typography>
                                                        }
                                                            secondary={<Tooltip title={desc}><Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{desc.length > 46 ? desc.slice(0, 43) + "..." : desc}</Typography> </Tooltip>
                                                            } 
                                                            />
                        
                            </ListItem>
                            </TableCell>
                            <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", width:"25%", borderBottom: "0px solid", margin: "auto"}} align="right">
                            {
                                element["configurationId"] != null 
                                ?  
                                element["configurationId"] != ""
                                ?
                                element["configurationId"] != "not available"
                                ?
                                <Tooltip title={"Link to configuration"}>
                                <IconButton className={globalClasses.customButton} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => window.open(pdmConfUrl + element["configurationId"], '_blank')}>
                                    <Settings />
                                </IconButton>
                                </Tooltip>
                                :
                                <></>
                                :
                                <></>
                                : 
                                <></>
                            }
                            </TableCell>
                        </TableRow>
                    </TableCell>
                    <TableCell className={classes.newbatchCell} align="center" onClick={element["status"] === "FAILED" ? () => handleOpenErrorPopup(b,element) : null} style={{ cursor: element["status"] === "FAILED" ? 'pointer' : 'default' }}>
                        <Tooltip title={element["status"]}>
                            {statusIcon}
                            {/* <RemoveCircleOutline className={classes.NotRunIcon} /> */}
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.newbatchCell} align="right" colSpan={3}>
                        <TableRow style={{width:"100%", display:"flex"}}>
                            <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"49%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="center">
                             
                            </TableCell>
                            <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"51%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="right">
                            {
                                GetEmpty(element["configurationId"]) == false && element["configurationId"] != "not available"
                                ?
                                <IconButton className={globalClasses.customButton} aria-controls="simple-menu" style={{float:"left"}} aria-haspopup="true" onClick={(e) => handleDownloadClick(e, element, b)}>
                                    <GetApp />
                                </IconButton>
                                :
                                <IconButton disabled={true} className={globalClasses.customButton} aria-controls="simple-menu" style={{float:"left"}} aria-haspopup="true" onClick={(e) => handleDownloadClick(e, element, b)}>
                                    <GetApp />
                                </IconButton>   
                            }
                            <Tooltip title={GetOrderErrorModeStatus(b, element)} style={{ marginLeft: "10%", marginRight: "30%", marginTop: "-0.5%",  textAlign: "center" }}>
                                <IconButton disabled={GetOrderErrorModeStatus(b, element).toLowerCase() != "errors"} 
                                onClick={() => handleErrorClick(b, element)}
                                style={{padding: 0}}>
                                    {GetOrderErrorMode(b, element)}
                                </IconButton>
                            </Tooltip>
                            </TableCell>
                        </TableRow>  
                    </TableCell>
                    <TableCell className={classes.newbatchCell} align="center" colSpan={4}>
                    <TableRow style={{width:"100%", display:"flex"}}>
                        <TableCell className={classes.orderCell} align="center" style={{fontSize: "smaller", width:"50%"}}>
                            <Tooltip title={"Cad Duration"} placement="bottom-start">
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <Timer fontSize='small' />
                                <Typography style={{paddingLeft: "4px"}}> {caD_duration}</Typography>
                            </Grid>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.orderCell} style={{paddingRight: "8px", fontSize: "smaller", width:"50%", paddingTop: "2%"}} align="right">
                            <Tooltip title={"Cad Loops"} placement="bottom-start">
                            <Grid container direction="row" alignItems="center" spacing={1} justifyContent="center">
                                <Replay fontSize='small' />
                                <Typography style={{paddingLeft: "4px"}}> {caD_loops}</Typography>
                            </Grid>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    </TableCell>
                </TableRow>
                );    
        }
        setCurrentOrderElements(orderElements);
    }

    useEffect(() => {
        setB(props.batch);
        setMainKey(mainKey + 1);
        RefreshOrders();
    }, [props.batch, props.reRender]);

    useEffect(() => {
        RefreshOrders();
    }, [b]);

    const GetErrorMode = (tmpBatch) => {
        //let modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
        //let modeIcon = <RemoveCircleOutline className={globalClasses.NotRunIcon} />
        let modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
        if (tmpBatch) {   
            if (tmpBatch["executeArguments"])
            {
                if (tmpBatch["executeArguments"]["cws_error_recording"])
                {
                    if (tmpBatch["executeArguments"]["cws_error_recording"] == "true")
                    {
                        //modeIcon = <ToggleOn className={globalClasses.successIcon} />
                        //modeIcon = <CheckCircleOutline className={globalClasses.successIcon} />
                        modeIcon = <Icon style={{backgroundColor: "#4BB543", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>On</Icon>
                    }   
                    else
                    {
                        //modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
                        //modeIcon = <RemoveCircleOutline className={globalClasses.NotRunIcon} />
                        modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
                    }
                }
            }
        }
        return modeIcon;
    };

    const GetErrorModeStatus = (tmpBatch) => {
        let modeStatus = "Off";
        if (tmpBatch) {   
            if (tmpBatch["executeArguments"])
            {
                if (tmpBatch["executeArguments"]["cws_error_recording"])
                {
                    if (tmpBatch["executeArguments"]["cws_error_recording"] == "true")
                    {
                        modeStatus = "On";
                    }   
                    else
                    {
                        modeStatus = "Off";
                    }
                }
            }
        }
        return modeStatus;
    };

    const GetSkipMode = (tmpBatch) => {
        //let modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
        let modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
        if (tmpBatch) {   
            if (tmpBatch["executeArguments"])
            {
                if (tmpBatch["executeArguments"]["cws_testing_skip_objects"])
                {
                    if (tmpBatch["executeArguments"]["cws_testing_skip_objects"] != "")
                    {
                        //modeIcon = <ToggleOn className={globalClasses.successIcon} />
                        modeIcon = <Icon style={{backgroundColor: "#4BB543", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>On</Icon>
                    }   
                    else
                    {
                        //modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
                        modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
                    }
                }
            }
        }
        return modeIcon;
    };

    const GetSkipModeStatus = (tmpBatch) => {
        let modeStatus = "Off";
        if (tmpBatch) {   
            if (tmpBatch["executeArguments"])
            {
                if (tmpBatch["executeArguments"]["cws_testing_skip_objects"])
                {
                    if (tmpBatch["executeArguments"]["cws_testing_skip_objects"] != "")
                    {
                        modeStatus = "On";
                    }   
                    else
                    {
                        modeStatus = "Off";
                    }
                }
            }
        }
        return modeStatus;
    };

    const GetOrderErrorMode = (tmpBatch, currentOrder) => {
        //let modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
        let modeIcon = <RemoveCircleOutline className={globalClasses.NotRunIcon} />
        //let modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
        // console.log("BATCH: ", tmpBatch);
        // console.log("currentOrder: ", currentOrder);
        
        let checkErrorModes = true;
        if (tmpBatch["errorModeStatus"])
        {
            if (tmpBatch["errorModeStatus"].toLowerCase() == "processing")
            {
                checkErrorModes = false;
                let foundExecOrder = false;
                if (tmpBatch["executeOrders"])
                {
                    if (tmpBatch["executeOrders"].length > 0)
                    {
                        tmpBatch["executeOrders"].forEach(element => {
                            if (currentOrder["name"].endsWith(element))
                            {
                                modeIcon = <Cached className={globalClasses.inProgressIcon} />
                                foundExecOrder = true;
                            }
                        });
                    }   
                }
            }
        }
        else
        {
            checkErrorModes = false;
        }
        if (props.batchesErrorModes != null && checkErrorModes == true)
        {
            // console.log("props.batchesErrorModes: ", props.batchesErrorModes);
            if (props.batchesErrorModes.length > 0)
            {
                let findBatch = props.batchesErrorModes.find(x => x.batchID == tmpBatch["id"]);
                if (findBatch)
                {
                    let findOrder = findBatch["errorModeOrders"].find(tmpOrder => tmpOrder.name == currentOrder.name);
                    if (findOrder)
                    {
                        modeIcon = <ErrorIcon className={globalClasses.errorIcon} />
                    }
                    else
                    {
                        let foundExecOrder = false;
                        if (tmpBatch["executeOrders"])
                        {
                            if (tmpBatch["executeOrders"].length > 0)
                            {
                                tmpBatch["executeOrders"].forEach(element => {
                                    if (currentOrder["name"].endsWith(element))
                                    {
                                        modeIcon = <SuccessIcon className={globalClasses.successIcon} />
                                    }
                                });
                            }   
                        }
                        
                    }
                }
            }
        }
        return modeIcon;
    };

    const GetOrderErrorModeStatus = (tmpBatch, currentOrder) => {
        //let modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
        let modeStatus = "Unavailable";
        //let modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
        // console.log("BATCH: ", tmpBatch);
        // console.log("currentOrder: ", currentOrder);
        
        let checkErrorModes = true;
        if (tmpBatch["errorModeStatus"])
        {
            if (tmpBatch["errorModeStatus"].toLowerCase() == "processing")
            {
                checkErrorModes = false;
                let foundExecOrder = false;
                if (tmpBatch["executeOrders"])
                {
                    if (tmpBatch["executeOrders"].length > 0)
                    {
                        tmpBatch["executeOrders"].forEach(element => {
                            if (currentOrder["name"].endsWith(element))
                            {
                                modeStatus = "Processing"
                                foundExecOrder = true;
                            }
                        });
                    }   
                }
            }
        }
        else
        {
            checkErrorModes = false;
        }
        if (props.batchesErrorModes != null && checkErrorModes == true)
        {
            // console.log("props.batchesErrorModes: ", props.batchesErrorModes);
            if (props.batchesErrorModes.length > 0)
            {
                let findBatch = props.batchesErrorModes.find(x => x.batchID == tmpBatch["id"]);
                if (findBatch)
                {
                    let findOrder = findBatch["errorModeOrders"].find(tmpOrder => tmpOrder.name == currentOrder.name);
                    if (findOrder)
                    {
                        modeStatus = "Errors";
                    }
                    else
                    {
                        let foundExecOrder = false;
                        if (tmpBatch["executeOrders"])
                        {
                            if (tmpBatch["executeOrders"].length > 0)
                            {
                                tmpBatch["executeOrders"].forEach(element => {
                                    if (currentOrder["name"].endsWith(element))
                                    {
                                        modeStatus = "Success";
                                    }
                                });
                            }   
                        }
                        
                    }
                }
            }
        }
        return modeStatus;
    };

    const GetOrderErrorModeErrors = (tmpBatch, currentOrder) => {
        //let modeIcon = <ToggleOff className={globalClasses.NotRunIcon} />
        let errors = [];
        //let modeIcon = <Icon style={{backgroundColor: "gray", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px", fontSize: "medium", color: "#fff"}}>Off</Icon>
        
        let checkErrorModes = true;
        if (tmpBatch["errorModeStatus"])
        {
            if (tmpBatch["errorModeStatus"].toLowerCase() == "processing")
            {
                checkErrorModes = false;
            }
        }
        else
        {
            checkErrorModes = false;
        }
        if (props.batchesErrorModes != null && checkErrorModes == true)
        {
            //console.log("props.batchesErrorModes: ", props.batchesErrorModes);
            if (props.batchesErrorModes.length > 0)
            {
                let findBatch = props.batchesErrorModes.find(x => x.batchID == tmpBatch["id"]);
                if (findBatch)
                {
                    let findOrder = findBatch["errorModeOrders"].find(tmpOrder => tmpOrder.name == currentOrder.name);
                    if (findOrder)
                    {
                        errors.push(findOrder["errors"]);
                    }
                }
            }
        }
        return errors;
    };

    return (
        <React.Fragment key={b["key"] + "_main" + mainKey}>
        <TableRow key={b["key"]} className={clsx({
            [classes.activeBatch]: selected, //currentSelection.indexOf(index3) > -1,
            [classes.batchDataRow]: true
        })}>
            <TableCell className={classes.newbatchCell} align="right" onClick={() => handleBatchClick(index3, b)}>
                {
                    b["status"] != "Processing" && props.sharedToMe == false
                    ?
                    <Tooltip title="Execute batch">
                    <IconButton className={clsx(globalClasses.customButton, globalClasses.blueColor)} onClick={(e)=>{
                        props.handleBatchExecuteOpenClick(e, b);
                        e.stopPropagation();
                    }}>
                        <PlayArrow />
                    </IconButton>
                    </Tooltip>
                :  
                <></>    
                }
                
            </TableCell>
            <TableCell className={classes.newbatchCell} style={{paddingLeft: "4px"}} align="left" onClick={() => handleBatchClick(index3, b)}>
                <ListItemText
                    style={{ margin: "0"}}
                    
                    disableTypography
                    primary={<Typography className={clsx({
                        [classes.activeBatchText]: selected, //currentSelection.indexOf(index3) > -1,
                        [globalClasses.smallFont]: true
                    })}>{b["name"] + ", " + (b["description"] == null ? " " : b["description"])}</Typography>}
                    secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{props.orderSecondaryText}</Typography> 
                }
                />
            </TableCell>
            <TableCell className={classes.newbatchCell} style={{paddingRight: "8px"}} align="center" onClick={() => handleBatchClick(index3, b)}>
                <Tooltip title={b["status"]}>
                    {props.statusIcon}
                </Tooltip>
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center">
                <Typography className={globalClasses.smallFont} onClick={() => handleBatchClick(index3, b)}>{b["material"] + "/" + b["materialRevision"]}</Typography>
                {
                    b["pdmDescription"] === undefined ? <></> : <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", whiteSpace: "pre-line"}} onClick={() => handleBatchClick(index3, b)}>{b["pdmDescription"]}</Typography>
                }
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" onClick={() => handleBatchClick(index3, b)}>
                {
                    b["status"] == "Success"
                    ?
                    <Tooltip title={"Download results for the entire batch"}>
                    <IconButton className={globalClasses.customButton} style={{ display: "flex", float: "left"}} onClick={(e) => handleBatchDownloadClick(b)}>
                        <GetApp />
                    </IconButton>
                    </Tooltip>
                    :
                    <IconButton disabled={true} className={globalClasses.customButton} style={{ display: "flex", float: "left"}} onClick={(e) => handleBatchDownloadClick(b)}>
                        <GetApp />
                    </IconButton>
                }
                <Tooltip title={GetErrorModeStatus(b)}>
                    {GetErrorMode(b)}
                </Tooltip>
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" onClick={() => handleBatchClick(index3, b)}>
                <Tooltip title={GetSkipModeStatus(b)}>
                    {GetSkipMode(b)}
                </Tooltip>
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" onClick={() => handleBatchClick(index3, b)}>
            <ListItemText
                style={{ margin: "0"}}
                disableTypography
                primary={<Typography className={globalClasses.smallFont}>{b["status"] == "Not run" ? " " : b["executeOrders"] === undefined ? " " : b["executeOrders"] == null ? " " : b["executeOrders"].length == 0 ? " " : getSuccessRate(b)}</Typography>}
                secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{b["lastRunBy"] == null ? " " : ConvertedTimeString(props.lastRun)}</Typography> 
            }
            />
                {/* <Typography className={globalClasses.smallFont} style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{
                new Date(props.lastRun).toUTCString()
                }</Typography> */}
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" onClick={() => handleBatchClick(index3, b)}>
                <Typography>{b["executeOrders"] === undefined ? " ": b["executeOrders"] == null ? " " : b["executeOrders"].length}</Typography>
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" onClick={() => handleBatchClick(index3, b)}>
                <Typography className={globalClasses.smallFont}>{b["orders"] === undefined || b["orders"] === null  ? 0 : b["orders"].length == 0 ? 0 : b["orders"].length}</Typography>
            </TableCell>
            <TableCell className={classes.newbatchCell} style={{paddingRight: "8px"}} align="right" onClick={() => handleBatchClick(index3, b)}>
                {/* <IconButton className={globalClasses.customButton} onClick={(e)=>{
                    e.stopPropagation();
                    handleEditClick(e, b, d["id"], d["revision"], orders);
                    }}>
                    <Edit />
                </IconButton> */}
                {
                    b["sharedTo"] === undefined ? <></> : 
                    b["sharedTo"] == null ? <></> :
                    b["sharedTo"].length > 0 ?
                    <IconButton style={{marginRight: "8px"}} className={globalClasses.customButton} onClick={(e)=>{
                        // Open shared with dialog
                        props.handleBatchShareOpenClick(e, shareObj, b);
                        e.stopPropagation();
                        }}>
                        <People />
                    </IconButton> 
                    : null
                }
                {
                    b["status"] == "Processing" && props.sharedToMe == false 
                    ?
                    <IconButton title={"Cancel"} style={{marginRight: "8px"}} className={globalClasses.customButton} onClick={(e)=>{
                        // Cancel button functionality
                        CancelButton(b);
                        e.stopPropagation();
                    }}>
                        <CancelOutlined />
                    </IconButton>
                    : 
                    <IconButton style={{width: "32px"}} className={globalClasses.customButton}>

                    </IconButton>
                }
                <IconButton className={globalClasses.customButton} onClick={(e)=>{
                    props.handleSetAnchorElBatch(e, b, d["id"], d["revision"], orders, shareObj);
                    e.stopPropagation();
                    }}>
                    <MoreHoriz />
                </IconButton>
            </TableCell>
        </TableRow>
        <Collapse  unmountOnExit in={selected}> 
        <TableContainer style={{ minHeight: "80px", maxHeight: "300px", overflow: "auto",    display: "contents"}}>
        <TableRow key={"order_table_" + b["key"]} style={{background:"seashell"}}>
            <TableCell className={classes.newbatchCell} align="center" colSpan={2}>
                <TableRow style={{width:"100%", display:"flex"}}>
                    <TableCell className={classes.newbatchCell} align="left" style={{fontSize: "smaller", fontWeight: "bold", width: "75%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%", marginLeft: "13%"}}>
                    {"Test Order"}
                    </TableCell>
                    <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"25%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="right">
                    {"Configuration"} 
                    </TableCell>
                </TableRow>
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" style={{fontSize: "smaller", fontWeight: "bold", paddingTop: "1%", paddingBottom: "1%"}}>
            {"Order Status"}
            </TableCell>
            <TableCell className={classes.newbatchCell} align="right" colSpan={3} style={{paddingRight: "50px", fontSize: "smaller", fontWeight: "bold", paddingTop: "1%", paddingBottom: "1%"}}>
            {"Error Mode Message"}
            </TableCell>
            <TableCell className={classes.newbatchCell} align="center" colSpan={4}>
                <TableRow style={{width:"100%", display:"flex"}}>
                    <TableCell className={classes.newbatchCell} align="center" style={{fontSize: "smaller", fontWeight: "bold", width: "50%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}}>
                    {"CAD configuration time"}
                    </TableCell>
                    <TableCell className={classes.newbatchCell} style={{paddingRight: "8px", fontSize: "smaller", fontWeight: "bold", width:"50%", borderBottom: "0px solid", paddingTop: "1%", paddingBottom: "1%"}} align="right">
                    {"CAD re-generation loops"}   
                    </TableCell>
                </TableRow>
            </TableCell>
        </TableRow>
        {orderElements == null ? <></> : orderElements}
        </TableContainer>
        </Collapse>
        {/* Error Dialog */}
        <Dialog open={errorPopupOpen} onClose={handleCloseErrorPopup} aria-labelledby="error-popup-title">
            <DialogTitle id="error-popup-title">Error</DialogTitle>
            <DialogContent>
            <p>{popupMessage}</p>
                <p>There was an error processing this batch. Please try again.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseErrorPopup} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open = {circularProgressOpen} id="CirProg">
        <DialogTitle>Downloading file</DialogTitle>
        <table width="200">
            <tr>
                <td width="200" align="center">
                    <CircularProgress />
                </td>
            </tr>
        </table>
        <DialogTitle></DialogTitle>
        </Dialog>
        </React.Fragment>
    );
}